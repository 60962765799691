import React from 'react';
import './Policies.css';
import "@fortawesome/fontawesome-free/css/all.css";

function Policies() {
  return (
    <div id="policies" className="main">
      <div className="booking-policies">
        <h1>Booking Policies</h1>
        <div className="policies-grid">
          <div className="policy-box">
            <i className="fas fa-dollar-sign"></i>
            <h2>Deposit Requirement</h2>
            <p>A <strong>£25 non-refundable deposit</strong> is required upon booking. The remaining balance can be paid in cash or card.</p>
          </div>
          <div className="policy-box">
            <i className="fas fa-exclamation-triangle icon"></i>
            <h2>Late Policy</h2>
            <p>Please arrive on time and notify us in advance if you’re running late. You have a <strong>15 minutes grace period</strong> and after that <strong>£10 late fee</strong> will apply. Appointments delayed by <strong>more than 30 minutes</strong> will be canceled without a refund.</p>
          </div>
          <div className="policy-box">
            <i className="fas fa-times-circle"></i>
            <h2>Cancellation & Rescheduling</h2>
            <p>A <strong>24 hours notice period</strong> is required prior to cancellation or rescheduling of any service.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Policies;
