import React from 'react';
import './ServiceDetails.css';
import { Option } from '../data';

function ServiceDetails({options, openModal}:{options: Option[], openModal: (path:string) => void}) {

    const openBooking = (path: string) => {
    openModal(path);
  };

  return (
    <div className="services-grid">
      {options.map((option, index) => (
        <div key={index} className="service-card">
          <h3 className="service-title">{option.name}</h3>
          <div className="service-info">
            <p>{option.duration} | {option.price}</p>
            {option.description.map((p) => (
              <p className='service-description'>{p}</p>
            ))}

            {/* Button to open the modal */}
            <button className='book' onClick={() => openBooking("https://tidycal.com/jsbhairdresser/" + option.bookingId)}>
              Book
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ServiceDetails;
