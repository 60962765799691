import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer id="footer" className="jsb-footer">
      <div className='footer'>
        <div className='footer-centralized-div'>
          <div className='footer-row-div'>
            <p><b>Je Suis Belle</b> by Jacira Silva</p>
            <p className='contact'>
              <span><i className='fas fa-mobile-alt'></i></span> (+44) 7950 832512<br /><span><i className='fas fa-envelope'></i></span> jsb.hairdresser@icloud.com
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
