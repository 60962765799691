import React, { useState } from 'react';
import './Header.css';
import logo from '../../LOGO JSB-04.png';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="jsb-header">
      <div className="header">
        <div className="header-centralized-div">
          <div className="header-row-div">
            <div className="header-left-col-div">
              <img src={logo} alt="JSB Logo" />
            </div>
            <div className="header-right-col-div">
              {/* Hamburger icon for smaller screens */}
              <div className="hamburger-menu" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
              </div>

              {/* Navigation menu */}
              <nav className={`menu-div ${isMenuOpen ? 'menu-open' : ''}`}>
                <menu className="horizontal-menu">
                  <li><a href="#services">Services</a></li>
                  <li><a href="#policies">Policies</a></li>
                  <li><a href="#footer">Contacts</a></li>
                </menu>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
