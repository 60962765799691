/* eslint-disable jsx-a11y/iframe-has-title */
import React, {useState, useEffect} from 'react';
import './App.css';
import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import ServiceView from './components/services/ServiceView';
import Modal from './components/bookings/Modal';
import banner1 from './resources/Banner_1.jpg';
import banner2 from './resources/Banner_2.jpg';
import Policies from './components/policies/Policies';

function App() {

  const [currentSlide, setCurrentSlide] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bookingPath, setbookingPath] = useState("");

  const openModal = (path: string) => {
    console.log(path);
    setbookingPath(path)
    setIsModalOpen(true);
  }
  const closeModal = () => setIsModalOpen(false);

  const slides = [
      { src: banner1, alt: 'Banner 1' },
      { src: banner2, alt: 'Banner 2' }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [slides.length]);

  const handleDotClick = (index: number) => {
      setCurrentSlide(index);
  };

  return (
    
    <div className="App">
      <Header />

      <div className="banner">
        <div className="carousel" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
            {slides.map((slide, index) => (
                <div key={index} className="carousel-slide">
                    <img src={slide.src} alt={slide.alt} />
                </div>
            ))}
        </div>

        {/* Dots for navigation */}
        <div className="dots">
            {slides.map((_, index) => (
                <div
                    key={index}
                    className={`dot ${index === currentSlide ? 'active' : ''}`}
                    onClick={() => handleDotClick(index)}
                ></div>
            ))}
        </div>
      </div>

      <ServiceView openModal={openModal} />

      {/* Button to open the modal */}
      <button className="float-button" onClick={() => openModal("")} style={{ padding: "10px 20px", fontSize: "16px" }}>
        <i className="fa fa-light fa-calendar"/>
      </button>

      {/* Modal with TidyCal iframe */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <iframe
          src= {bookingPath === "" ? "https://tidycal.com/jsbhairdresser" : bookingPath }
          width="100%"
          height="600px"
          frameBorder="0"
          title="TidyCal Booking"
        ></iframe>
      </Modal>

      <Policies />

      <Footer />
    </div>
  );
}

export default App;
